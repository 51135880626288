import React, { Component } from 'react';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';
import Main from './Main';
import Answers from './Answers';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';

import './App.scss';
import logo from './assets/six-in-a-row-logo.png';


const styles = {
  h2: {
    fontWeight: 300,
    lineHeight: '1rem',
    fontSize: '0.8rem',
  },
  card: {
    borderRadius: '8px',
    backgroundColor: 'grey',
  },
  headerLink: {
    fontWeight: 500,
  }
};


class App extends Component {
  render() {
    return (
      <Router><ScrollToTop>
        <div className="container-fluid">

          <section className="header">

            <section>
              <header style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                <div style={{whiteSpace: 'nowrap'}}>
                  <Link
                    to="/"
                    id="home"
                    style={{display: 'flex', alignItems: 'center'}}
                    >
                    <img
                      src={logo}
                      alt="logo: a numeral six centered in a stylized calendar"
                      />
                    <h1>
                      <span>Six In A Row</span>
                    </h1>
                  </Link>
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end'}}>
                  <Link
                    to="/answers"
                    id="answers"
                    style={styles.headerLink}
                    >
                    Questions
                  </Link>
                </div>
              </header>
            </section>

          </section>

          <div style={{textAlign: 'left'}}>
            <Route exact path="/" component={Main} />
            <Route path="/answers" component={Answers} />
            <Route path="/terms" component={TermsAndConditions} />
            <Route path="/privacy" component={PrivacyPolicy} />
          </div>

          <section className="footer">
            <section>
              <footer>
                <div class="heels">
                  Created in a Vancouver apartment with hard-working people and busy lives in mind.<br />😅❤️
                </div>
                <div>
                  <Link
                    to="/terms"
                    id="terms"
                    >
                    Terms of Use
                  </Link>
                  &nbsp;&nbsp;&nbsp;
                  <Link
                    to="/privacy"
                    id="privacy"
                    >
                    Privacy Policy
                  </Link>
                </div>
                &copy; Six In A Row
              </footer>
            </section>
          </section>
        </div>
      </ScrollToTop></Router>
    );
  }
}


export default App;