import React, { Component } from 'react';
import './answers.scss';


const appFaq = [
  { question: "Why am I good looking?",
    answer: <><p>Our customers are simply the best people on the planet.</p></> },
  { question: "How do I edit an event?",
    answer: <><p>Events are listed above the schedule. The event that is active will have a color background. If the event is already active, tap on it to edit it. If the event is not active, tap it once to activate it, and again to edit it.</p></> },

  { question: "How do I create an overnight event?",
    answer: <>
      <p>First, ensure the All-day flag is unset. This will display the time pickers. Set the start time of the event. Set the end time of the event to be before the start time. This will automatically mark the end time as "(next day)".</p>
      <p>Unfortunately at this time Six In A Row does not support events of 24hrs or longer.</p>
    </> },
  { question: "Can I add an event with a duration of 24hrs or more?",
    answer: <><p>Unfortunately at this time Six In A Row does not support events of 24hrs or longer.</p></> },
  { question: "How far back can I swipe the calendar?",
    answer: <><p>The calendar can be swiped back 12 months from the present month.</p></> },
  { question: "I have a repeating schedule. How can I copy and paste a rotation?",
    answer: <><p>Rotation extending features are planned for Six In A Row v2.0, which has yet to be released.</p></> },
];
const syncFaq = [
  { question: "What does \"Override event colors\" do?",
    answer: <><p>Switching this on will override your event colors with your chosen calendar color. Leaving it off will leave your event and calendar colors as assigned.</p></> },
  { question: "I can't find my new calendar in the Google Calendar mobile app.",
    answer: <>
      <p className="heels-none">On <b>Android</b>, try to sync your new calendar:</p>
      <ol className="heels-half">
        <li>Open Google Calendar on your Android device</li>
        <li>Tap the <i>button with three vertical dots</i> (top right), then tap <i>Refresh</i>. Wait for it to finish (may take 2 minutes or more).</li>
        <li>Open the side menu, tap <i>Settings</i>, then tap the name of your new calendar, and turn sync to <i>on</i></li>
      </ol>
      <p className="heels-none">On <b>iOS</b>, try enabling your new calendar:</p>
      <ol className="heels-half">
        <li>Open Google Calendar on your Apple device</li>
        <li>Open the side menu, tap the name of your new calendar</li>
      </ol>
    </> },
  { question: "",
    answer: <><p></p></> },
  { question: "",
    answer: <><p></p></> },
];

const styles = {
  faqItem: {
    borderTop: '1px solid #ddd',
    paddingTop: '8px',
  },
  faqItemQuestion: {
    fontSize: '20px',
    cursor: 'pointer',
    marginBottom: '8px',
  },
  faqItemQuestionActive: {
    fontWeight: 500,
  },
  faqItemAnswer: {
    fontSize: '16px',
    marginBottom: '16px',
    marginLeft: '16px',
  },
};

const FaqItem = (props) => {
  if (props.data.question === "") {
    return null;
  }

  const isActive = props.activeKey === props.index;

  return (
    <li style={styles.faqItem}>
      <p
        className="faqItemQuestion"
        style={isActive ? {...styles.faqItemQuestion, ...styles.faqItemQuestionActive} : styles.faqItemQuestion}
        onClick={props.handleActivateFaqItem}
        >
        {props.data.question}
      </p>
      <div
        style={isActive ? styles.faqItemAnswer : {display: 'none'}}
        >
        {props.data.answer}
      </div>
    </li>
  );
};


class Faq extends Component {
  state = {
    activeKey: -1,
  };
  render() {
    return (
      <section>
        <section>
          <h2 style={{marginBottom: '1rem'}}>Need answers?</h2>
          <p>Check out the Frequently Asked Questions below. If you still can't find what you're after, Contact Us.</p>
        </section>
        <section>
          <h3 style={{marginBottom: '32px'}}>Frequently Asked Questions</h3>
          <h4>Using the App</h4>
          <ul>
            {appFaq.map((item, index) => (
              <FaqItem
                data={item}
                key={index}
                index={index}
                activeKey={this.state.activeKey}
                handleActivateFaqItem={() => this.handleActivateFaqItem(index)}
                />
            ))}
          </ul>
          <h4 style={{marginTop: '32px'}}>Syncing with Google Calendar</h4>
          <ul>
            {syncFaq.map((item, index) => (
              <FaqItem
                data={item}
                key={index}
                index={index}
                activeKey={this.state.activeKey}
                handleActivateFaqItem={() => this.handleActivateFaqItem(index)}
                />
            ))}
          </ul>
        </section>
        <section>
          <h3>Contact Us</h3>
          <p>We're always happy to hear from you. Shoot us an email at:</p>
          <p>
            <a
              className="btn btn-primary"
              href="mailto:hello@sixinarow.io"
              id="contact"
              >
              hello@sixinarow.io
            </a>
          </p>
        </section>
      </section>
    );
  }
  handleActivateFaqItem = (key) => {
    let newActiveKey = -1;
    if (this.state.activeKey !== key) {
      newActiveKey = key;
    }

    this.setState({
      activeKey: newActiveKey
    });
  }
}


export default Faq;