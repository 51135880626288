 import React, { Component } from 'react';
import mobileDetect from 'mobile-detect';

import './App.scss';
//import logo from './assets/six-in-a-row-logo.png';

/*import defineShift from './assets/define-shifts-4.mp4';
import defineShiftWebm from './assets/define-shifts-4.webm';
import defineShiftOgg from './assets/define-shifts-4.ogg';
import defineShiftImg from './assets/define-shifts-4.png';*/
import defineShift from './assets/sixinarow-iphone5s-bezel-3-add-events.mp4';
import defineShiftWebm from './assets/sixinarow-iphone5s-bezel-3-add-events.webm';
import defineShiftOgg from './assets/sixinarow-iphone5s-bezel-3-add-events.ogg';
import defineShiftImg from './assets/sixinarow-iphone5s-bezel-add-events.png';

/*import scheduleShifts from './assets/schedule-shifts-4.mp4';
import scheduleShiftsWebm from './assets/schedule-shifts-4.webm';
import scheduleShiftsOgg from './assets/schedule-shifts-4.ogg';
import scheduleShiftsGif from './assets/schedule-shifts-4-trim-faster.gif';
import scheduleShiftsImg from './assets/schedule-shifts.png';*/
import scheduleShifts from './assets/sixinarow-iphone5s-bezel-3-schedule-export.mp4';
import scheduleShiftsWebm from './assets/sixinarow-iphone5s-bezel-3-schedule-export.webm';
import scheduleShiftsOgg from './assets/sixinarow-iphone5s-bezel-3-schedule-export.ogg';
import scheduleShiftsGif from './assets/sixinarow-iphone5s-bezel-3-schedule-export.gif';
import scheduleShiftsImg from './assets/sixinarow-iphone5s-bezel-schedule-export.png';


import shiftList from './assets/shift-list.png';
import atypicalPatterns from './assets/atypical-pattern-2.mp4';
import atypicalPatternsWebm from './assets/atypical-pattern-2.webm';
import atypicalPatternsOgg from './assets/atypical-pattern-2.ogg';
import atypicalPatternsGif from './assets/atypical-pattern-2-1056-faster.gif';
import atypicalPatternsImg from './assets/atypical-patterns.png';
import googleCalendarLogo from './assets/google-calendar-logo.png';

//import comingSoon from './assets/coming_soon_stores.png';
import googlePlay from './assets/google-play.png';
import appleStore from './assets/appstore.svg';
import appleStoreSoon from './assets/App-Store-Badge-Coming-Soon.png';

/*const styles = {
  h2: {
    fontWeight: 300,
    lineHeight: '1rem',
    fontSize: '0.8rem',
  },
  card: {
    borderRadius: '8px',
    backgroundColor: 'grey',
  }
};*/


// Video strategy:
//  on non-mobile devices, autoplay the video
//  on mobile devices, fallback to gif where it works
//  on mobile devices where gif doesn't play nice, use video with controls
const userAgent = new mobileDetect(navigator.userAgent);
const videoAutoplays = userAgent.mobile() === null;  // Assume video doesn't autoplay on any mobile devices
const gifIsProblematic = () => {
  let os = userAgent.os();
  if (os && os.includes('Android')) { os = "Android"; }
  const v = userAgent.version(os);

  switch (os) {
    case "iOS":
      if (v < 12) {
        return true; // iOS 12 plays the gif fine, but iOS 11 plays it slow
      }
      break;
    default:
      break;
  }

  return false; // Assume gifs play fine except for in older iOS versions
}


class Main extends Component {
  componentDidMount() {
    // Preload top image
    const img1 = new Image();
    img1.src = scheduleShiftsImg;
    const gif1 = new Image();
    gif1.src = scheduleShiftsGif;
  }
  render() {
    return (
      <div style={{textAlign: 'center'}}>
        <section>

          <section>

            <div className="row justifyCenter hats-3 hats-md">
              <div className="col-md-10 no-bottom-margin-sm">
                <h2 className="heels-none heels-sm">Stay on top of your schedule <br />painlessly.</h2>
              </div>
            </div>

            <div className="row alignStart justifyCenter videos">
              <div className="col-md-6">
                <div className="videoContainerLeft">
                  {videoAutoplays || gifIsProblematic() ? (
                    <video
                      muted
                      autoPlay
                      playsinline
                      controls={gifIsProblematic() ? true : null}
                      loop
                      poster={scheduleShiftsImg}
                      id="schedule-shifts"
                      preload
                      >
                      <source src={scheduleShifts} type="video/mp4" />
                      <source src={scheduleShiftsWebm} type="video/webm" />
                      <source src={scheduleShiftsOgg} type="video/ogg" />
                      Video unavailable
                    </video>
                  ) : (
                    <img
                      src={scheduleShiftsGif}
                      alt="how to build a schedule with Six In A Row"
                      />
                  )}
                </div>
              </div>
              <div className="col-md-6 d-none d-md-block">
                <div className="videoContainerRight">
                  {videoAutoplays ? (
                    <video
                      muted
                      autoPlay
                      playsinline
                      loop
                      id="define-shifts"
                      poster={defineShiftImg}
                      preload
                      >
                      <source src={defineShift} type="video/mp4" />
                      <source src={defineShiftWebm} type="video/webm" />
                      <source src={defineShiftOgg} type="video/ogg" />Video unavailable
                    </video>
                  ) : (
                    <img
                      src={defineShiftImg}
                      alt="how to build an event with Six In A Row"
                      />
                  )}
                </div>
              </div>
            </div>

            <div className="row justifyCenter">
              <div className="col-md-10 heels">
                <h2 className="heels-double">Save your time.</h2>
                <h4>Ideal for shift work and irregular schedules</h4>
                <h5 className="heels">whether you get two weeks or a year of schedule at once.</h5>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <center className="heels">
                  <a
                    href="https://itunes.apple.com/app/id1449800743"
                    target="_blank"
                    rel="noopener noreferrer"
                    id="ios-get"
                    >
                    <img
                      src={appleStore}
                      alt="available on the Apple App Store"
                      style={{maxHeight: '59px', margin: '10px'}}
                      />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=io.sixinarow"
                    target="_blank"
                    rel="noopener noreferrer"
                    id="android-get"
                    >
                    <img
                      src={googlePlay}
                      alt="get it on Google Play"
                      style={{maxHeight: '62px', margin: '10px'}}
                      />
                  </a>
                </center>
              </div>
            </div>

          </section>
        </section>

        <section className="shaded features">
          <section>

            {/* Desktop Headings */}
            <div className="d-none d-md-flex row heels-half">
              <div className="col-md-4">
                <h4 className="numbered">1. Set regular events</h4>
              </div>
              <div className="col-md-4">
                <h4 className="numbered">2. Schedule events</h4>
              </div>
              <div className="col-md-4">
                <h4 className="numbered">3. Save and Share</h4>
              </div>
            </div>

            {/* Mobile Columns / Desktop Images */}
            <div className="row">
              <div className="col-md-4 align-self-center">
                <h4 className="d-md-none heels-half numbered">1. Set regular events</h4>
                <img
                  src={shiftList}
                  alt="an example of an event list Six In A Row"
                  style={{maxWidth: '250px', width: '100%'}}
                  />
                <div className="d-md-none heels-half hats-half">
                </div>
              </div>
              <div className="col-md-4 align-self-center">
                <h4 className="d-md-none heels-half numbered">2. Schedule events</h4>
                {videoAutoplays ? (
                  <video
                    muted
                    autoPlay
                    playsinline
                    loop
                    poster={atypicalPatternsImg}
                    id="atypical-patterns"
                    preload
                    style={{maxWidth: '350px', width: '100%'}}
                    >
                    <source src={atypicalPatterns} type="video/mp4" />
                    <source src={atypicalPatternsWebm} type="video/webm" />
                    <source src={atypicalPatternsOgg} type="video/ogg" />
                    Video unavailable
                  </video>
                ) : (
                  <img
                    src={gifIsProblematic() ? atypicalPatternsImg : atypicalPatternsGif}
                    alt="how to build a schedule with Six In A Row"
                    style={{maxWidth: '350px', width: '100%'}}
                    />
                )}
              </div>
              <div className="col-md-4 align-self-center">
                <h4 className="d-md-none heels-half numbered">3. Save and Share</h4>
                <img
                  src={googleCalendarLogo}
                  alt="Google Calendar logo"
                  style={{maxHeight: '100px'}}
                  />
                <div className="d-md-none heels-half hats-half">
                </div>
              </div>
            </div>

            {/* Desktop Notes */}
            <div className="d-none d-md-flex row heels-half">
              <div className="col-md-4">
              </div>
              <div className="col-md-4">
              </div>
              <div className="col-md-4">
              </div>
            </div>

          </section>
        </section>

        <section>
          <section>
            <div className="row">
              <div className="col-12">
                <h3 className="hats-half">First month FREE.<br/><br/>🎉</h3>
              </div>
            </div>

            <h5 className="heels-half">Limited time offer:</h5>
            <div className="row d-flex justify-content-center">
              <div className="col-md-4 col-sm-6">
                <div className="sub-tier align-items-stretch">
                  <h4 className="bold">Essential Features</h4>
                  <h4 style={{marginBottom: 0}}>$1.99 USD</h4>
                  <p className="smallGrey" style={{fontSize: '14px'}}>one-time purchase</p>
                  <p className="smallGrey">Core interface<br/>+ sync with Google Calendar<br/><b>forever</b></p>
                </div>
              </div>
              {/*<div className="col-md-3 col-sm-4">
                <div className="sub-tier">
                  <h4 className="bold">Seasonally</h4>
                  <h4 className="heels-none">$3.99</h4>
                  <div className="smallGrey">per 3 months</div>
                  <div className="smallGrey">($1.33 / month)</div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="sub-tier">
                  <h4 className="bold">Annually</h4>
                  <h4 className="heels-none">$11.99</h4>
                  <div className="smallGrey">per year</div>
                  <div className="smallGrey">($1 / month)</div>
                </div>
              </div>*/}
            </div>
            <div className="heels-double">
              <p className="hats-half" style={{fontSize: '0.6rem'}}><super>*</super> In countries with relatively low purchasing power,<br/>android users pay 50% or sometimes 25% of the converted price.</p>
              <h5 className="hats heels">Don't pay until after your free month!</h5>
            </div>

            {/*<div className="row heels d-flex justify-content-center">
              <div className="col-md-7">
                <p class="smallGrey super-small">Prices shown are in USD. Prices may vary by country of purchase, and in some cases are discounted according to average cost of living and relative purchasing power. See inside app for local pricing.</p>
              </div>
            </div>

            <div className="row heels-double d-flex justify-content-center">
              <div className="col-md-3 col-sm-4 col-7">
                <h5>☕️<br/>Daily cup of coffee<br/>(homemade)</h5>
                <div className="smallGrey">~$0.15&nbsp;</div>
                <div className="smallGrey">x 1 (cups per day)</div>
                <div className="smallGrey">x 30 (days per month)</div>
                <hr />
                <div>~${(0.15 * 30).toFixed(2)} / month</div>
              </div>
            </div>*/}
          </section>
        </section>

        <section className="shaded" style={{backgroundColor: '#def'}}>
          <section>
            <div className="row">
              <div className="col-12">
                <h3>Join our mailing list</h3>
                <h4 className="heels">Updates, offers, and more</h4>
                <a
                  href="https://goo.gl/forms/jDSLD8XIDw8KbhHe2"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="general-email"
                  className="btn btn-primary heels-half"
                  >
                  Subscribe
                </a>
              </div>
            </div>
          </section>
        </section>

      </div>
    );
  }
}


export default Main;
